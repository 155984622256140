<template>
  <b-container>
    <div class="answer-examination-test-back" id="examination-pdf">
      <div class="student-answer-information">
        <EndHead @downPdf="downPdf" :result="result"> </EndHead>
        <EndCard :result="result"> </EndCard>
      </div>
      <div class="student-score-information">
        <EndForm
          :score="score"
          :subjectiveColor="`#2CB774`"
          :objectiveColor="`#FFC13B`"
          :objectiveTest = "`客观题`"
          :subjectiveTest = "`主观题`"
        >
        </EndForm>
      </div>
      <div class="student-test-information">
        <EndTest
          :sections="sections"
          :unfold="unfold"
          @addWriteAnsers="addWriteAnsers"
          @changeRadio="changeRadio"
        >
        </EndTest>
      </div>
    </div>
  </b-container>
</template> 

<script>
import { getEngReport } from "@/api/english-examination";
import { mapMutations, mapState } from "vuex";
import EndCard from "@/components/english-end/end-card.vue";
import EndForm from "@/components/english-end/end-form.vue";
import EndTest from "@/components/english-end/end-test.vue";
import EndHead from "@/components/english-end/end-head.vue";
// var audio = new Audio();
export default {
  data() {
    return {
      check: false,
      sections: "",
      answers: {},
      result: {},
      unfold: [true, true, true, true, true, true, true, true],
      textHtml: "",
      showLoading: false,
      testData: {},
      voiceStates: {},
      score: {},
      subjectiveColor: "#FB8F67",
      objectiveColor: "#FFC13B",
      htmlTitle:'培立优中考英语冲刺战备体检报告'
    };
  },
  components: {
    EndCard,
    EndForm,
    EndTest,
    EndHead,
  },
  mounted() {
    this.getSections();
    // this.simulationExamQuestions = this.sections;
    // if (true) {
    //   this.getSections();
    // } else {
    //   this.sections = this.simulationExamQuestions;
    //   this.answers = this.userAnswers;
    this.sections = this.simulationExamQuestions;
    // }
  },
  computed: {
    ...mapState(["simulationExamQuestions", "userAnswers"]),
  },
  beforeRouteLeave(to, from, next){
    sessionStorage.removeItem("simulationExamQuestions");
    next();
  },
  methods: {
    ...mapMutations(["setSimulationExamQuestions", "setUserAnswers"]),
    downPdf(){
        this.getPdf('examination-pdf')
     
    },
    getSections(id = this.$route.query.studentExamId) {
      this.check = false;
      this.unfold = [true, true, true, true, true, true, true, true];
      // debugger;

      if (id) {
        getEngReport({ recordExamId: this.$route.query.studentExamId }).then((res) => {
          this.result = res.data.result.engFinishSummary;
          this.sections = res.data.result.examSectionInfos;
          this.score = res.data.result.scoreDistribution;
          // this.setSimulationExamQuestions(this.sections);
          // this.userAnswersInit();
          this.testData = res.data.result;
          console.log("this.testData", this.testData);
        });
      }
    },
    // 选项的css
    getClassName(question, option) {
      console.log("question", question, "option", option);
      if (question.standardAnswerKey === option.value) {
        if (question.userAnswerKey === option.value) {
          return ["enter-radio", "enter-text"];
        }
        return "enter-text";
      } else {
        if (question.userAnswerKey === option.value) {
          return ["error-radio", "error-text"];
        }
      }
      return "";
    },
    // 音频播放
    // startVoice(key, url) {
    //   console.log("音频url", url);
    //   audio.src = url;
    //   audio.play();
    //   this.voiceStates = {};
    //   this.$set(this.voiceStates, key, true);
    //   const self = this;
    //   audio.addEventListener("ended", function () {
    //     self.stopVoice();
    //   });

    //   console.log("播放状态管理", this.voiceStates);
    // },
    // 音频播放
    // stopVoice() {
    //   this.voiceStates = {};
    //   audio.pause();
    // },
    // getInput(index, questionId) {
    //   var h = document
    //     .getElementsByClassName(questionId)[0]
    //     .getElementsByTagName("input");
    //   console.log("html答案", index, h);
    //   const values = Array.prototype.map.call(h, (item) => item.value);
    //   var sesValue = JSON.parse(sessionStorage.getItem("values"))
    //     ? JSON.parse(sessionStorage.getItem("values"))
    //     : {};
    //   sesValue[questionId] = values;
    //   window.sessionStorage.setItem("values", JSON.stringify(sesValue));
    // },
    // 写作内容
    // textareaChange(value, index, questionId, state) {
    //   console.log("写作内容", value, index, questionId);
    //   for (const a of this.answers.answerSectionInputs[index]
    //     .answerQuestionInputs) {
    //     if (a.questionId === questionId) {
    //       if (state === 0) a.answer = value;
    //       if (state === 1) a.selfScore = value;
    //       break;
    //     }
    //   }
    //   this.setUserAnswers(this.answers);
    // },
    changeRadio(value, questionId, setionId) {
      console.log("模拟试卷", this.answers);
      for (const a of this.answers.answerSectionInputs) {
        if (a.sectionId === setionId) {
          let isHave = false;
          for (const b of a.answerQuestionInputs) {
            if (b.questionId === questionId) {
              b.answer = value;
              isHave = true;
              break;
            }
          }
          if (!isHave) {
            a.answerQuestionInputs.push({
              questionId: questionId,
              answer: value,
            });
          }
        }
      }
      this.setUserAnswers(this.answers);
    },
    userAnswersInit() {
      this.answers = {
        examId: this.$route.query.id,
        answerMinutes: 0,
        answerSectionInputs: [],
      };
      for (const a of this.sections) {
        this.answers.answerSectionInputs.push({
          sectionId: a.sectionId,
          sectionType: a.sectionType,
          answerQuestionInputs: [],
        });
      }
      this.setUserAnswers(this.answers);
    },
    // 非听力展开试题

    addWriteAnsers(index, questions) {
      debugger
      console.log('aaaa0',this.answers)
      for (const a of questions) {
        for (const b of a.sectionAnswerRecords) {
          this.answers.answerSectionInputs[index].answerQuestionInputs.push({
            selfScore: "",
            questionId: b.questionId,
            answer: "",
          });
        }
      }
      this.setUserAnswers(this.answers);
    },
    // getSimulation(id) {
    //   if (this.simulationExamQuestions[id]) {
    //     return this.simulationExamQuestions[id].simulationExamQuestions;
    //   } else {
    //     return [];
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.answer-examination-test-back {
  // min-height: 100vw;

  letter-spacing: 1px;
  .student-answer-information {
    background-color: #ffffff;
    min-height: 40rem;
    border-radius: 10px;
    padding: 3rem 2rem;
  }
  .student-score-information {
    background-color: #ffffff;
    min-height: 40rem;
    border-radius: 10px;
    padding: 3rem 2rem;
    margin: 1rem 0;
  }
  .student-test-information {
    background-color: #ffffff;
    min-height: 40rem;
    border-radius: 10px;
    padding: 3rem 2rem;
  }
}
</style>
<template>
  <div class="end-card">
    <b-container>
      <b-row :class="CardBg()">
        <b-col class="card-people d-none d-sm-block" cols="0" sm="2">
          <img
            src="../../assets/images/index_pic2.png"
            class="card-img"
            alt=""
          />
        </b-col>
        <b-col class="card-details">
          <b-row class="details-rating">
            <b-row class="details-border">
              <b-row class="details-bg">
                <div>
                  <span class="iconfont icon-defen2"></span>
                  <span class="details-test">体检得分:</span>
                  <span class="details-result">{{ result.testScore }}分 </span>
                </div>
                <div>
                  <span class="iconfont icon-test"></span>
                  <span class="details-test">定级关卡:</span>
                  <span class="details-result">{{
                    gradingSprintId(result.gradingSprintId)
                  }}</span>
                </div>
              </b-row>
            </b-row>
          </b-row>
          <b-row class="student-ranking">
            <b-col>
              <div class="card-percentage">
                {{ result.classRanking  | turnPercentage }}%
              </div>
              <div class="rank-test">班级排名</div>
              <div class="test-number">
                <div class="iconfont icon-tishi"></div>
                当前班级完成测试人数
                <span>{{ result.classFinishNumber }}</span>
                人
              </div>
            </b-col>
            <b-col>
              <div class="card-percentage">
                {{ result.schoolRanking  | turnPercentage }}%
              </div>
              <div class="rank-test">全校排名</div>
              <div class="test-number">
                <div class="iconfont icon-tishi"></div>
                当前全校完成测试人数
                <span>{{ result.schoolFinishNumber }}</span>
                人
              </div>
            </b-col>
            <b-col>
              <div class="card-percentage">
                {{ result.districtRanking  | turnPercentage }}%
              </div>
              <div class="rank-test">全区排名</div>
              <div class="test-number">
                <div class="iconfont icon-tishi"></div>
                当前全区完成测试人数
                <span>{{ result.districtFinishNumber }}</span>
                人
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="card-summary">
        <b-col cols="12">
          <span class="iconfont icon-pingjia1"></span>
          <span class="card-test">体检总结</span>
        </b-col>
        <div class="summary-test" v-html="result.examSummary"></div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    result: {
      default: function () {
        return {};
      },
    },
  },
  methods: {
    gradingSprintId(gradingSprintId) {
      console.log("gradingSprintId", gradingSprintId);
      if (gradingSprintId == 2) {
        return "扫雷尖兵";
      }
      if (gradingSprintId == 4) {
        return "沙漠行动";
      }
      if (gradingSprintId == 7) {
        return "抢滩登陆";
      }
    },
    CardBg() {
      return "examination-bg";
    },
  },
};
</script>

<style lang="scss" scoped>
.end-card {
  text-align: left;
  letter-spacing: 1px;
  color: $text-light-gray;
  padding-top: 5rem;

  .card-test {
    font-weight: bold;
  }
  .card-img {
    width: 92%;
  }
  .examination-bg {
    color: $text-white;
    background-image: url("../../assets/images/examination-bg.png");
    background-size: 100%;
    height: 15rem;
    background-repeat: no-repeat;
    .card-people {
      position: relative;
      img {
        position: absolute;
        top: -45px;
        left: 0;
      }
    }
  }
  .card-details {
    position: relative;
    .student-ranking {
      width: 100%;
      position: absolute;
      top: 5vw;
    }
  }
  .card-percentage {
    padding-left: 1.7rem;
    font-size: 1.6vw;
  }
  .rank-test {
    padding: 0.3rem 1.7rem;
    font-size: 1vw;
  }
  .test-number {
    display: flex;
    align-items: center;
    justify-items: center;
    span {
      color: $text-yellow;
      padding: 0 3px;
    }
  }
  .icon-tishi {
    padding-right: 5px;
  }
  .details-rating {
    position: absolute;
    top: -1rem;
    .details-border {
      width: 28.25vw;
      height: 57px;
      border: 1px solid #fb8f67;
      transform: skewX(-31deg);
      position: relative;
      .details-bg {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 28.25vw;
        height: 57px;
        position: absolute;
        left: 1.5rem;
        top: -0.5rem;
        outline: none;
        background-color: #fb8f67;
        & > div {
          transform: skewX(31deg);
          .icon-defen2,
          .icon-test {
            font-size: 1.5vw;
            color: $text-yellow;
            padding-left: 2rem;
            padding-right: 0.5rem;
          }
          .details-test {
            font-size: 1.1vw;
          }
          .details-result {
            font-size: 1.3vw;
            padding-left: 1rem;
          }
        }
      }
    }
  }
  .card-summary {
    padding-top: 2rem;
    .icon-pingjia1 {
      color: $text-green;
      font-size: 1.2rem;
      padding-right: 5px;
    }
    .summary-test {
      padding-top: 1rem;
      padding-left: 1rem;
      color: #000;
      line-height: 1.8;
    }
  }
}
</style>